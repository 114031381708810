import React from 'react'

interface SourceProps{
  lowSrc?: string
  media: string
  srcSet: any
}

const Source = ({ lowSrc, media, srcSet }:SourceProps) => (
    <source data-srcset={srcSet} data-lowsrc={lowSrc} media={media} />
  );

const Sources = (image:string | undefined, rules:{[key:string]: string}, imageBreakpoints:{[key: string]: number}): JSX.Element[] => {
    const lowSrc = `${image}?w=100&auto=format`
    const srcs = Object.entries(imageBreakpoints).reduce(
      (acc, [key, val]) => ({
        ...acc,
        [key]: `${image}?w=${val * 2}&auto=format`,
      }),
      {}
    )

    const sources = Object.entries(srcs).map(([key, src]) => {
      const rule = rules[key]
      return <Source key={key} lowSrc={lowSrc} media={rule} srcSet={src} />
    })
    return sources
  }

  export default Sources;